/* eslint-disable */
import { Collapse } from "antd";
import { useTranslation } from "react-i18next";
import TrendSettings from "./TrendSettings";
const TrendSettingsCommon = ({
  trendCommonSettingsData,
  postSettings,
}) => {
  const { t } = useTranslation();
  const { Panel } = Collapse;
  /*
 const TrendAnalysisWarningCollapse = () => {
   const result = []
   // (trendCommonSettingsData[i]?.trendGroupName === 2 || trendCommonSettingsData[i]?.trendGroupName === 3) --- temporary constraints (should be removed later)
   for (let i = 0; i < trendCommonSettingsData?.length; i++) {
     if ((trendCommonSettingsData[i] !== null) && (trendCommonSettingsData[i] !== undefined) && (trendCommonSettingsData[i]?.trendGroupName === 2 || trendCommonSettingsData[i]?.trendGroupName === 3)) {
       result.push(
           <Panel header={`Предупреждение ТА-${trendCommonSettingsData[i]?.trendGroupName}`} key={`warning${trendCommonSettingsData[i]?.trendGroupName}`}>

           <TrendWarningSettings
             handleSetNewData={postSettings}
             warningData={trendCommonSettingsData && trendCommonSettingsData[i]}
             warningId={trendCommonSettingsData && trendCommonSettingsData[i]?.trendGroupName}
           />
         </Panel>,
       )
     }
   }

   return result
 }
*/
  const result = [];
  if (trendCommonSettingsData?.length) {
    for (let i = 0; i < trendCommonSettingsData?.length; i++) {

      let headerName = ""

      if (trendCommonSettingsData[i]?.trendGroupName === 2) {
        headerName = "Снижение дебита в том числе из-за увеличения ГФ (2 группа)"
      } else if (trendCommonSettingsData[i]?.trendGroupName === 3) {
        headerName = "Нестабильная работа из-за снижения или роста Pпл и PI (3 группа)"
      } else {
        headerName = `Группа ${trendCommonSettingsData?.[i]?.trendGroupName}`
      }
      result.push(
        <Collapse accordion ghost>
          <Panel
            header={headerName}
            key={trendCommonSettingsData[i]?.uniqueId}
          >
            <TrendSettings
              handleSetNewData={postSettings}
              warningData={
                trendCommonSettingsData && trendCommonSettingsData[i]
              }
              warningId={
                trendCommonSettingsData &&
                trendCommonSettingsData[i]?.trendGroupName
              }
            />
          </Panel>
        </Collapse>
      );
    }
  }

  return result;
};

export default TrendSettingsCommon;
