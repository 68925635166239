/* eslint-disable */
import { Input, Form, Switch, Button, Collapse } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import TrendData from "./TrendData";

const TrendWells = ({
                        handleSearch,
                        wellsData,
                        warningData,
                        handleSetNewData,
                      }) => {
  const { Panel } = Collapse;
  const { t } = useTranslation();

  const wellLines = () => {
    const result = [];
    for (let i = 0; i < wellsData?.length; i++) {
      result.push(
        <Collapse accordion ghost>
          <Panel
            style={{
              display: "flex",
              textAlign: "Left",
              flexDirection: "column",
            }}
            key={`warning${wellsData[i]}`}
            header={wellsData[i]?.name}
          >
            {
              parameterLines(warningData[i].trendIndividualSettings)
            }
            {/* <TrendData
              warningData={warningData[i].trendIndividualSettings}
              handleSetNewData={handleSetNewData}
              warningId={wellsData}
            /> */}
          </Panel>
        </Collapse>
      );
    }
    return result;
  };

  const parameterLines = (inputData) => {
    const result = [];
      for (let i = 0; i < inputData?.length; i++) {
          result.push(
            <Collapse accordion ghost>
          <Panel
            style={{
              display: "flex",
              textAlign: "Left",
              flexDirection: "column",
            }}
            key={`warning${wellsData[i]}`}
            header={`Группа ${i + 1}`}
          >
            <TrendData
              warningData={inputData[i]}
              handleSetNewData={handleSetNewData}
              warningId={wellsData}
            />
          </Panel>
          </Collapse>
          )
      }

      return result;
  }


  return (
    <div>
      <Input
        addonBefore={"Поиск"}
        onChange={(e) => handleSearch(e.target.value)}
        style={{ paddingBottom: "20px" }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          overflowY: "auto",
          overflowX: "hidden",
          height: "400px",
          flexDirection: "column",
        }}
      >
        {wellLines()}
      </div>
    </div>
  );
};
export default TrendWells;
