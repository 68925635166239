import React from "react";
import BackStepIcon from "./BackStepIcon";
import {useTranslation} from "react-i18next";

const RenderModalTitle = ({step, setStep, setButtonText}) => {
  const {t} = useTranslation();
  const settingNames = [
    t("analysisSettings"),
    t("reasonsSettings"),
    t("instantCommonSettings"),
    t("trendAnalysisCommonSettings"),
    t("rangingSettings"),
    t("Virtual_flow_meter_settings"),
    t("DivergenceSettings"),
    t("specialAnalysisSettings"),
    t("setpointsSettings"),
    t("trendAnalysisIndividualSettings"),
  ]

  switch (step) {
    case "analysis":
      return <BackStepIcon setStep={setStep} setButtonText={setButtonText} settingNames={settingNames[0]}/>
    case "reasons":
      return <BackStepIcon setStep={setStep} setButtonText={setButtonText} settingNames={settingNames[1]}/>
    case "instantCommonSettings":
      return <BackStepIcon setStep={setStep} settingNames={settingNames[2]}/>
    case "trendAnalysisCommonSettings":
      return <BackStepIcon setStep={setStep} settingNames={settingNames[3]}/>
    case "rangingSettings":
      return <BackStepIcon setStep={setStep} settingNames={settingNames[4]}/>
    case "virtualMeasureSettings":
      return <BackStepIcon setStep={setStep} settingNames={settingNames[5]}/>
    case "divergenceSettings":
      return <BackStepIcon setStep={setStep} settingNames={settingNames[6]}/>
    case "specialAnalysisSettings":
      return <BackStepIcon setStep={setStep} settingNames={settingNames[7]}/>
    case "setpointsSettings":
      return <BackStepIcon setStep={setStep} settingNames={settingNames[8]}/>
    case "trendAnalysisIndividualSettings":
      return <BackStepIcon setStep={setStep} settingNames={settingNames[9]}/>
    default:
      return t("wellTreeSettings");
  }
};

export default React.memo(RenderModalTitle);