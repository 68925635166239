/* eslint-disable */

import React, { useEffect, useState } from "react";
import "antd/dist/antd.min.css";
import {
  Form,
  Button,
  InputNumber,
  Switch,
  Space,
  Input,
  TimePicker,
  DatePicker, //  Typography,
} from "antd";
import { useTranslation } from "react-i18next";

const TrendConstraint = ({
  handleSetNewData,
  constraintId,
  constraintData,
  settingsType,
}) => {
  return (
    <>
      <Form.Item
        labelAlign="left"
        label={`Коэф. угла наклона апроксимируемой прямой`}
        rules={[
          { required: true, message: "Введите ограничения для апроксимации" },
        ]}
      >
        <span className="ant-form-text" style={{ paddingLeft: "5px" }}>
          {" "}
          1{" "}
        </span>
        <Form.Item
          noStyle
          name={[
            `${settingsType}`,
            `${constraintId}`,
            `approxConstraintsFirst`,
          ]}
          rules={[
            { required: true, message: "Введите ограничения для апроксимации" },
          ]}
        >
          <InputNumber
            // min={0}
            // max={999}
            style={{
              width: "30%",
            }}
          />
        </Form.Item>

        <span className="ant-form-text" style={{ paddingLeft: "5px" }}>
          {" "}
          2{" "}
        </span>
        <Form.Item
          noStyle
          name={[`${settingsType}`, `${constraintId}`, `approxConstraintsLast`]}
          rules={[
            { required: true, message: "Введите ограничения для апроксимации" },
          ]}
        >
          <InputNumber
            // min={0}
            // max={999}
            style={{
              width: "30%",
            }}
          />
        </Form.Item>
      </Form.Item>

      <Form.Item
        labelAlign="left"
        label={`Настройка определения изменения показателя`}
        rules={[{ required: true, message: "Введите изменение показателя" }]}
      >
        <span className="ant-form-text" style={{ paddingLeft: "5px" }}>
          {" "}
          {"   "}
        </span>
        <Form.Item
          noStyle
          name={[`${settingsType}`, `${constraintId}`, `deltaValueConstraint`]}
          rules={[{ required: true, message: "Введите изменение показателя" }]}
        >
          <InputNumber
            min={0}
            max={999}
            style={{
              width: "30%",
            }}
          />
        </Form.Item>
      </Form.Item>

      <Form.Item
        labelAlign="left"
        label={`Настройки для определения поведения «пилы»`}
        rules={[{ required: true, message: "Введите коэфициенты «пилы»" }]}
      >
        <span className="ant-form-text" style={{ paddingLeft: "5px" }}>
          {" "}
          1{" "}
        </span>
        <Form.Item
          noStyle
          name={[`${settingsType}`, `${constraintId}`, `sawConstraint1`]}
          sawConstraint2
          rules={[{ required: true, message: "Введите коэфициент «пилы» 1" }]}
        >
          <InputNumber
            // min={0}
            // max={999}
            style={{
              width: "20%",
            }}
          />
        </Form.Item>

        <span className="ant-form-text" style={{ paddingLeft: "5px" }}>
          {" "}
          2{" "}
        </span>
        <Form.Item
          noStyle
          name={[`${settingsType}`, `${constraintId}`, `sawConstraint2`]}
          rules={[{ required: true, message: "Введите коэфициент «пилы» 2" }]}
        >
          <InputNumber
            // min={0}
            // max={999}
            style={{
              width: "20%",
            }}
          />
        </Form.Item>
        <span className="ant-form-text" style={{ paddingLeft: "5px" }}>
          {" "}
          3{" "}
        </span>

        <Form.Item
          noStyle
          name={[`${settingsType}`, `${constraintId}`, `sawConstraint3`]}
          rules={[{ required: true, message: "Введите коэфициент «пилы» 3" }]}
        >
          <InputNumber
            // min={0}
            // max={999}
            style={{
              width: "20%",
            }}
          />
        </Form.Item>
      </Form.Item>
    </>
  );
};

export default TrendConstraint;
