/* eslint-disable */
import React, { useEffect, useState } from "react";
import "antd/dist/antd.min.css";
import {
  Form,
  Button,
  InputNumber,
  Switch,
  Collapse,
  TimePicker,
  DatePicker,
  Input,
  Modal,
} from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import TrendConstraint from "../../components/TrendConstraint";
import TrendConstraintLoading from "../../components/TrendConstraintLoading";
import TrendConstraintSaw from "../../components/TrendConstraintSaw";
import TrendConstraintApprox from "../../components/TrendConstraintApprox";
import localeRU from "antd/es/date-picker/locale/ru_RU";
import localeEN from "antd/es/date-picker/locale/en_GB";
import localeES from "antd/es/date-picker/locale/es_ES";

const TrendSettings = ({ handleSetNewData, warningId, warningData }) => {
  const { t } = useTranslation();
  const storageTranslations = JSON.parse(
    sessionStorage.getItem("translations")
  );

  const [buttonText, setButtonText] = useState(t("apply"));

  const chooseTranslateFromArray = (id, array, lang) => {
    // const translationIndex = array && array[lang] && array[lang].findIndex(x => x.parameterId === id)

    // return (translationIndex >= 0)
    //   ? array[lang][translationIndex].translation ? array[lang][translationIndex].translation : alias
    //   : id

    const translationIndex =
      array &&
      array[lang] &&
      array[lang].findIndex((x) => x.parameterId === id);
    const parameterIndex =
      array &&
      array?.parameters &&
      array?.parameters.findIndex((x) => x.id === id);

    if (translationIndex >= 0) {
      return array[lang][translationIndex].translation
        ? array[lang][translationIndex].translation
        : array?.parameters[parameterIndex].alias;
    }

    return parameterIndex >= 0 ? array?.parameters[parameterIndex].alias : id;
  };

  const makeResultSettings = (data) => {
    let result = {};
    result["trendGroupName"] = warningData.trendGroupName;
    result["status"] = Boolean(data.status);
    result["delayResponse"] = {
      year: 0,
      month: 0,
      day: 0,
      hour: 0,
      minute: data.delayResponseMinutes,
      second: 0,
    };
    result["minDataFrequency"] = {
      year: 0,
      month: 0,
      day: 0,
      hour: 0,
      minute: data.minDataFrequencyMinutes,
      second: 0,
    };
    result["analysisPeriodMin"] = {
      year: 0,
      month: 0,
      day: data.analysisPeriodMinDays,
      hour: data.analysisPeriodMinHours,
      minute: 0,
      second: 0,
    };
    result["analysisPeriodMax"] = {
      year: 0,
      month: 0,
      day: data.analysisPeriodMaxDays,
      hour: data.analysisPeriodMaxHours,
      minute: 0,
      second: 0,
    };
    result["timeDiscr"] = {
      year: 0,
      month: 0,
      day: data.timeDiscrDays,
      hour: data.timeDiscrHours,
      minute: 0,
      second: 0,
    };
    result["analysisTime"] = data.analysisTime?._d.toLocaleTimeString([], {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    result["trendConstraints"] = [];

    warningData.trendConstraints.forEach((constraint) => {
      if (data.hasOwnProperty(constraint.parameterId)) {
        let updatedConstraint = data[`${constraint.parameterId}`];
        updatedConstraint["settingsId"] = constraint.settingsId;
        updatedConstraint["parameterId"] = constraint.parameterId;
        result["trendConstraints"].push(updatedConstraint);
      }
    });
    return result;
  };

  const onFinish = (values) => {
    values.trendSettingsCommon.warningId = warningId;
    handleSetNewData(makeResultSettings(values.trendSettingsCommon));
    setButtonText(t("applied"));
  };

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  let locale = "";

  moment.locale("ru");
  switch (currentLanguage) {
    case "ru": {
      locale = localeRU;
      moment.locale("ru");
      break;
    }
    case "en": {
      locale = localeEN;
      moment.locale("en-gb");
      break;
    }
    case "es": {
      locale = localeES;
      moment.locale("es");
      break;
    }
    default: {
      locale = localeRU;
      moment.locale("ru");
      break;
    }
  }

  const makeWarningConstrasintsCollapse = () => {
    const result = [];

    for (let i = 0; i < warningData?.trendConstraints?.length; i++) {
      if (
        warningData?.trendConstraints[i] !== null &&
        warningData?.trendConstraints[i] !== undefined
      ) {
        result.push(
          <Panel
            header={`Предупреждение параметр = ${chooseTranslateFromArray(
              warningData?.trendConstraints[i].parameterId,
              storageTranslations,
              currentLanguage
            )}`}
            key={warningData?.trendConstraints[i].parameterId}
          >
            <TrendConstraintApprox
              constraintData={warningData?.trendConstraints[i]}
              constraintId={warningData?.trendConstraints[i].parameterId}
              settingsType={"trendSettingsCommon"}
            />
          </Panel>
        );
      }
    }
    return result;
  };
  // full stack of options (temporary commented)
  /* const makeWarningConstrasintsCollapse = () => {
    const result = [];

    for (let i = 0; i < warningData?.trendConstraints?.length; i++) {

      if (
        warningData?.trendConstraints[i] !== null &&
        warningData?.trendConstraints[i] !== undefined
      ) {
        if (warningId === 1 && (warningData?.trendConstraints[i].parameterId === 2 || warningData?.trendConstraints[i].parameterId === 6)) {
          result.push(
              <Panel
                  header={`Предупреждение параметр = ${chooseTranslateFromArray(
                      warningData?.trendConstraints[i].parameterId,
                      storageTranslations,
                      currentLanguage
                  )}`}
                  key={warningData?.trendConstraints[i].parameterId}
              >
                <TrendConstraintApprox
                    constraintData={warningData?.trendConstraints[i]}
                    constraintId={warningData?.trendConstraints[i].parameterId}
                    settingsType={"trendSettingsCommon"}
                />
                <TrendConstraintLoading
                    constraintData={warningData?.trendConstraints[i]}
                    constraintId={warningData?.trendConstraints[i].parameterId}
                    settingsType={"trendSettingsCommon"}
                />

              </Panel>
          )
        } else if ((warningId === 4 || warningId === 9) && (warningData?.trendConstraints[i].parameterId === 2 || warningData?.trendConstraints[i].parameterId === 6)) {
          result.push(
              <Panel
                  header={`Предупреждение параметр = ${chooseTranslateFromArray(
                      warningData?.trendConstraints[i].parameterId,
                      storageTranslations,
                      currentLanguage
                  )}`}
                  key={warningData?.trendConstraints[i].parameterId}
              >
                <TrendConstraintApprox
                    constraintData={warningData?.trendConstraints[i]}
                    constraintId={warningData?.trendConstraints[i].parameterId}
                    settingsType={"trendSettingsCommon"}
                />
                <TrendConstraintSaw
                    constraintData={warningData?.trendConstraints[i]}
                    constraintId={warningData?.trendConstraints[i].parameterId}
                    settingsType={"trendSettingsCommon"}
                />
              </Panel>
          )
        } else
        result.push(
          <Panel
            header={`Предупреждение параметр = ${chooseTranslateFromArray(
              warningData?.trendConstraints[i].parameterId,
              storageTranslations,
              currentLanguage
            )}`}
            key={warningData?.trendConstraints[i].parameterId}
          >
            <TrendConstraintApprox
              constraintData={warningData?.trendConstraints[i]}
              constraintId={warningData?.trendConstraints[i].parameterId}
              settingsType={"trendSettingsCommon"}
            />
          </Panel>
        );
      }
    }

    return result;
  };  */

  // const { TextArea } = Input
  const format = "HH:mm:ss";
  // const fromatDate = 'YYYY-MM-DD'
  const { Panel } = Collapse;

  // useEffect(() => {
  //   setIsAverageCurrentStatus(Boolean(warningData?.averageCurrentStatus))
  //   setIsAverageLoadStatus(Boolean(warningData?.averageLoadStatus))
  // }, [warningData])

  const layout = {
    labelCol: {
      span: 14,
    },
    wrapperCol: {
      span: 12,
    },
  };

  const makeInitialValues = () => {
    let initialValues = {
      trendSettingsCommon: {
        status: warningData?.status,
        delayResponse: warningData?.delayResponse,
        analysisPeriodMaxHours: warningData?.analysisPeriodMax.hour,
        analysisPeriodMinHours: warningData?.analysisPeriodMin.hour,
        analysisPeriodMaxDays: warningData?.analysisPeriodMax.day,
        analysisPeriodMinDays: warningData?.analysisPeriodMin.day,
        minDataFrequencyMinutes: warningData?.minDataFrequency.minute,
        delayResponseMinutes: warningData?.delayResponse.minute,
        timeDiscrDays: warningData?.timeDiscr.day,
        timeDiscrHours: warningData?.timeDiscr.hour,
        trendConstraints: warningData?.trendConstraints,
        analysisTime: moment(warningData.analysisTime, format),
      },
    };

    let key = null;

    for (let i = 0; i < warningData?.trendConstraints?.length; i++) {
      if (warningData?.trendConstraints[i]?.parameterId) {
        key = warningData?.trendConstraints[i]?.parameterId;
        initialValues["trendSettingsCommon"][`${key}`] = {
          approxConstraintsFirst:
          warningData?.trendConstraints[i]?.approxConstraintsFirst,
          approxConstraintsLast:
          warningData?.trendConstraints[i]?.approxConstraintsLast,
          deltaValueConstraint:
          warningData?.trendConstraints[i]?.deltaValueConstraint,
          sawConstraint1: warningData?.trendConstraints[i]?.sawConstraint1,
          sawConstraint2: warningData?.trendConstraints[i]?.sawConstraint2,
          sawConstraint3: warningData?.trendConstraints[i]?.sawConstraint3,
        };
      }
    }

    return initialValues;
  };

  return (
    <Form
      {...layout}
      name="nest-messages"
      onFinish={onFinish}
      className
      initialValues={makeInitialValues()}
      onValuesChange={() => setButtonText(t("apply"))}
    >
      <Form.Item
        name={["trendSettingsCommon", "status"]}
        valuePropName="checked"
        label="Трендовые настройки ВКЛ/ВЫКЛ"
        labelAlign="left"
        wrapperCol={{
          offset: 0,
          span: 16,
        }}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label={"Время старта анализа"}
        name={["trendSettingsCommon", "analysisTime"]}
      >
        <TimePicker locale={locale} />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Отсрочка срабатывания"
        rules={[{ required: false, message: "Введите интервал" }]}
      >
        <Form.Item
          noStyle
          name={["trendSettingsCommon", "delayResponseMinutes"]}
          rules={[{ required: false, message: "Введите минуты интервала" }]}
        >
          <InputNumber
            min="0"
            max="999"
            style={{
              width: "30%",
            }}
          />
        </Form.Item>

        <span className="ant-form-text" style={{ paddingLeft: "5px" }}>
          {" "}
          {t("shortMinutes")}{" "}
        </span>
      </Form.Item>

      <Form.Item
        labelAlign="left"
        label="Минимальная частота данных"
        rules={[{ required: false, message: "Введите интервал" }]}
      >
        <Form.Item
          noStyle
          name={["trendSettingsCommon", "minDataFrequencyMinutes"]}
          rules={[{ required: false, message: "Введите минуты интервала" }]}
        >
          <InputNumber
            min="0"
            max="999"
            style={{
              width: "30%",
            }}
          />
        </Form.Item>

        <span className="ant-form-text" style={{ paddingLeft: "5px" }}>
          {" "}
          {t("shortMinutes")}{" "}
        </span>
      </Form.Item>

      <Form.Item
        labelAlign="left"
        label="Минимальный период"
        rules={[{ required: false, message: "Введите интервал" }]}
      >
        <Form.Item
          noStyle
          name={["trendSettingsCommon", "analysisPeriodMinDays"]}
          rules={[{ required: false, message: "Введите сутки интервала" }]}
        >
          <InputNumber
            min="0"
            max="365"
            style={{
              width: "30%",
            }}
          />
        </Form.Item>

        <span className="ant-form-text" style={{ paddingLeft: "5px" }}>
          {" "}
          {t("shortDays")}{" "}
        </span>
        <Form.Item
          noStyle
          name={["trendSettingsCommon", "analysisPeriodMinHours"]}
          rules={[{ required: false, message: "Введите часы интервала" }]}
        >
          <InputNumber
            min="0"
            max="24"
            style={{
              width: "30%",
            }}
          />
        </Form.Item>
        <span className="ant-form-text" style={{ paddingLeft: "5px" }}>
          {" "}
          {t("shortHours")}{" "}
        </span>
      </Form.Item>

      <Form.Item
        labelAlign="left"
        label="Максимальный период"
        rules={[{ required: false, message: "Введите интервал" }]}
      >
        <Form.Item
          noStyle
          name={["trendSettingsCommon", "analysisPeriodMaxDays"]}
          rules={[{ required: false, message: "Введите сутки интервала" }]}
        >
          <InputNumber
            min="0"
            max="365"
            style={{
              width: "30%",
            }}
          />
        </Form.Item>

        <span className="ant-form-text" style={{ paddingLeft: "5px" }}>
          {" "}
          {t("shortDays")}{" "}
        </span>
        <Form.Item
          noStyle
          name={["trendSettingsCommon", "analysisPeriodMaxHours"]}
          rules={[{ required: false, message: "Введите часы интервала" }]}
        >
          <InputNumber
            min="0"
            max="24"
            style={{
              width: "30%",
            }}
          />
        </Form.Item>
        <span className="ant-form-text" style={{ paddingLeft: "5px" }}>
          {" "}
          {t("shortHours")}{" "}
        </span>
      </Form.Item>

      <Form.Item
        labelAlign="left"
        label="Дискретность анализа"
        rules={[{ required: false, message: "Введите интервал" }]}
      >
        <Form.Item
          noStyle
          name={["trendSettingsCommon", "timeDiscrDays"]}
          rules={[{ required: false, message: "Введите сутки интервала" }]}
        >
          <InputNumber
            min="0"
            max="365"
            style={{
              width: "30%",
            }}
          />
        </Form.Item>

        <span className="ant-form-text" style={{ paddingLeft: "5px" }}>
          {" "}
          {t("shortDays")}{" "}
        </span>
        <Form.Item
          noStyle
          name={["trendSettingsCommon", "timeDiscrHours"]}
          rules={[{ required: false, message: "Введите часы интервала" }]}
        >
          <InputNumber
            min="0"
            max="24"
            style={{
              width: "30%",
            }}
          />
        </Form.Item>
        <span className="ant-form-text" style={{ paddingLeft: "5px" }}>
          {" "}
          {t("shortHours")}{" "}
        </span>
      </Form.Item>

      <div style={{ paddingLeft: "20px" }}>
        <Collapse accordion ghost>
          {makeWarningConstrasintsCollapse()}
        </Collapse>
      </div>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 10 }}>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            backgroundColor: buttonText === t("apply") ? "" : "lightseagreen",
          }}
        >
          {buttonText}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default TrendSettings;
