/* eslint-disable */
import {Collapse, Input} from "antd";
import { useTranslation } from "react-i18next";
import TrendSettingsCommon
  from "../../../WellTree/components/MenuData/components/ControlPanel/components/TrendAnalysisModal/TrendSettingsCommon";
import TrendWells
  from "../../../WellTree/components/MenuData/components/ControlPanel/components/TrendAnalysisModal/TrendSettingsIndividual/TrendWells";
import React from "react";

const TrendAnalysisWarningCollapse = ({
                               trendCommonSettingsData,
                               postSettings,
                               commonIndividual,
                               handleSetNewData,
                               wellsData,
                               warningData,
                               handleSearch,
                               trendIndividualSettingsData
                             }) => {
  const { t } = useTranslation();
  const { Panel } = Collapse;

  if (trendCommonSettingsData?.length /*&& specialData?.length*/) {
    const result = [];

    for (let i = 0; i < commonIndividual.length; i++) {
      if (commonIndividual[i] === commonIndividual[0]) {
        result.push(
          <Collapse accordion ghost>
            <Panel
              header={t(commonIndividual[0])}
              key={trendCommonSettingsData[i]?.uniqueId}
            >
              <Collapse accordion ghost>
                <TrendSettingsCommon
                  trendCommonSettingsData={trendCommonSettingsData}
                  postSettings={postSettings}
                />
              </Collapse>
            </Panel>
          </Collapse>
        );
      } else {
        result.push(
          <Collapse accordion ghost>
            <Panel
              header={t(commonIndividual[1])}
              key={trendCommonSettingsData[i]?.uniqueId}
            >
              <TrendWells
                handleSetNewData={postSettings}
                wellsData={wellsData}
                warningData={trendIndividualSettingsData}
                handleSearch={handleSearch}
              />
            </Panel>
          </Collapse>
        );
      }
    }
    return result;
  }
  return <></>;
};

export default TrendAnalysisWarningCollapse;
