/* eslint-disable */

import React, { useEffect, useState } from 'react'
import 'antd/dist/antd.min.css'
import {
  Form, Button, InputNumber, Switch, Space, Input, TimePicker, DatePicker, //  Typography,
} from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Collapse } from 'antd'
import localeRU from 'antd/es/date-picker/locale/ru_RU'
import localeEN from 'antd/es/date-picker/locale/en_GB'
import localeES from 'antd/es/date-picker/locale/es_ES'
import TrendConstraint from "../../../components/TrendConstraint";

const { TextArea } = Input
const format = 'HH:mm:ss'
const fromatDate = 'YYYY-MM-DD'
const { Panel } = Collapse

const TrendData = ({ handleSetNewData, warningId, warningData }) => {
  const { t } = useTranslation()
  // const [isAverageCurrentStatus, setIsAverageCurrentStatus] = useState(false)
  // const [isAverageLoadStatus, setIsAverageLoadStatus] = useState(false)
  // const [isCurrentManualState, setIsCurrentManualState] = useState(false)
  // const [isLoadManualState, setIsLoadManualState] = useState(false)

  const storageTranslations = JSON.parse(sessionStorage.getItem('translations'))
  const chooseTranslateFromArray = (id, array, lang) => {
    const translationIndex = array && array[lang] && array[lang].findIndex(x => x.parameterId === id)

    return (translationIndex >= 0)
      ? array[lang][translationIndex].translation ? array[lang][translationIndex].translation : alias
      : id
  }

  const makeResultSettings = data => {
    let result = {}
    result['trendGroupName'] = warningData.trendGroupName
    result['wellId'] = warningData.wellId
    result['status'] = Boolean(data.status)
    result['state'] = Boolean(data.state)
    result['delayResponse'] = {
      year: 0,
      month: 0,
      day: data.delayResponseDays,
      hour: data.delayResponseHours,
      minute: 0,
      second: 0,
    }
    result['minDataFrequency'] = {
      year: 0,
      month: 0,
      day: data.minDataFrequencyDays,
      hour: data.minDataFrequencyHours,
      minute: 0,
      second: 0,
    }
    result['analysisPeriodMin'] = {
      year: 0,
      month: 0,
      day: data.analysisPeriodMinDays,
      hour: data.analysisPeriodMinHours,
      minute: 0,
      second: 0,
    }
    result['analysisPeriodMax'] = {
      year: 0,
      month: 0,
      day: data.analysisPeriodMaxDays,
      hour: data.analysisPeriodMaxHours,
      minute: 0,
      second: 0,
    }
    result['timeDiscr'] = {
      year: 0,
      month: 0,
      day: data.timeDiscrDays,
      hour: data.timeDiscrHours,
      minute: 0,
      second: 0,
    }
    result['analysisTime'] = data.analysisTime._d.toLocaleTimeString([], {hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit'})
    result['unapprovalReason'] = data.unapprovalReason
    result['mop'] = {
      year: 0,
      month: 0,
      day: data.mopDays,
      hour: data.mopHours,
      minute: 0,
      second: 0,
    }

    result['trendConstraints'] = []

    warningData.trendConstraints.forEach(constraint => {
      if (data.hasOwnProperty(constraint.parameterId)) {
        let updatedConstraint = data[`${constraint.parameterId}`]
        updatedConstraint['settingsId'] = warningData.settingsId
        updatedConstraint['parameterId'] = warningData.parameterId
        result['trendConstraints'].push(updatedConstraint)
      }
    })
    result['lastMopDate'] = data.lastMopDate._d

    return result
  }

  const onFinish = values => {
    values.trendSettingsIndividual.warningId = warningId
    handleSetNewData(makeResultSettings(values.trendSettingsIndividual))
  }

  const { i18n } = useTranslation()
  const currentLanguage = i18n.language

  let locale = ''

  moment.locale('ru')
  switch (currentLanguage) {
    case 'ru': {
      locale = localeRU
      moment.locale('ru')
      break
    }
    case 'en': {
      locale = localeEN
      moment.locale('en-gb')
      break
    }
    case 'es': {
      locale = localeES
      moment.locale('es')
      break
    }
    default: {
      locale = localeRU
      moment.locale('ru')
      break
    }
  }

  // useEffect(() => {
  //   setIsAverageCurrentStatus(Boolean(warningData?.averageCurrentStatus))
  //   setIsAverageLoadStatus(Boolean(warningData?.averageLoadStatus))
  //   setIsCurrentManualState(Boolean(warningData?.currentManualState))
  //   setIsLoadManualState(Boolean(warningData?.loadManualState))
  // }, [warningData])

  const layout = {
    labelCol: {
      span: 14,
    },
    wrapperCol: {
      span: 12,
    },
  }

  const makeWarningConstrasintsCollapse = () => {
    const result = []

    for (let i = 0; i < warningData?.trendConstraints?.length; i++) {
      if ((warningData?.trendConstraints[i] !== null) && (warningData?.trendConstraints[i] !== undefined)) {
        result.push(
          <Panel header={`Предупреждение параметр = ${chooseTranslateFromArray(warningData?.trendConstraints[i].parameterId, storageTranslations, currentLanguage)}`} key={warningData?.trendConstraints[i].parameterId}>
            <TrendConstraint
              // handleSetNewData={getDataFromComponent}
              constraintData={warningData?.trendConstraints[i]}
              constraintId={warningData?.trendConstraints[i].parameterId}
              settingsType={'trendSettingsIndividual'}
            />
          </Panel>,
        )
      }
    }

    return result
  }

  const makeInitialValues = () => {

    let initialValues = {
      trendSettingsIndividual: {
        status: warningData?.status,
        delayResponse: warningData?.delayResponse,
        state: warningData?.state,
        analysisPeriodMaxHours: warningData?.analysisPeriodMax.hour,
        analysisPeriodMinHours: warningData?.analysisPeriodMin.hour,
        analysisPeriodMaxDays: warningData?.analysisPeriodMax.day,
        analysisPeriodMinDays: warningData?.analysisPeriodMin.day,
        minDataFrequencyDays: warningData?.minDataFrequency.day,
        minDataFrequencyHours: warningData?.minDataFrequency.hour,
        delayResponseDays: warningData?.delayResponse.day,
        delayResponseHours: warningData?.delayResponse.hour,
        timeDiscrDays: warningData?.timeDiscr.day,
        timeDiscrHours: warningData?.timeDiscr.hour,
        unapprovalReason: warningData?.unapprovalReason,
        mopHours: warningData?.mop.hour,
        mopDays: warningData?.mop.day,
        lastMopDate: moment(new Date(warningData?.lastMopDate), fromatDate),
        trendConstraints: warningData?.trendConstraints,
        analysisTime: moment(warningData?.analysisTime, format),
      },
    }

    let key = null

    for (let i = 0; i < warningData?.trendConstraints?.length; i++) {
      if (warningData?.trendConstraints[i]?.parameterId) {
        key = warningData?.trendConstraints[i]?.parameterId
        initialValues['trendSettingsIndividual'][`${key}`] = {
          approxConstraintsFirst: warningData?.trendConstraints[i]?.approxConstraintsFirst,
          approxConstraintsLast: warningData?.trendConstraints[i]?.approxConstraintsLast,
          deltaValueConstraint: warningData?.trendConstraints[i]?.deltaValueConstraint,
          sawConstraint1: warningData?.trendConstraints[i]?.sawConstraint1,
          sawConstraint2: warningData?.trendConstraints[i]?.sawConstraint2,
          sawConstraint3: warningData?.trendConstraints[i]?.sawConstraint3,
        }
      }
    }

    return initialValues
  }

  return (
    <Form
      {...layout}
      name="nest-messages"
      onFinish={onFinish}
      className
      initialValues={
        makeInitialValues()
      }
    >
      <Form.Item
        name={['trendSettingsIndividual', 'status']}
        valuePropName="checked"
        label="Индивидуальные настройки ВКЛ/ВЫКЛ"
        labelAlign="left"
        wrapperCol={{
          offset: 0,
          span: 16,
        }}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        name={['trendSettingsIndividual', 'state']}
        valuePropName="checked"
        label="Статус"
        labelAlign="left"
        wrapperCol={{
          offset: 0,
          span: 16,
        }}
      >
        <Switch />
      </Form.Item>
      <Form.Item labelAlign="left" label={'Время последнего анализа'} name={['trendSettingsIndividual', 'analysisTime']}>
        <TimePicker locale={locale} />
      </Form.Item>
      <Form.Item labelAlign="left" label="Отсрочка срабатывания" rules={[{ required: false, message: 'Введите интервал' }]} >
        <Form.Item noStyle
                   name={['trendSettingsIndividual', 'delayResponseDays']}
                   rules={[{ required: false, message: 'Введите сутки интервала' }]}
        >
          <InputNumber
            // min={0}
            // max={999}
            style={{
              width: '30%',
            }}
          />
        </Form.Item>

        <span className="ant-form-text" style={{paddingLeft: '5px'}}> {t('shortDays')} </span>
        <Form.Item noStyle
                   name={['trendSettingsIndividual', 'delayResponseHours']}
                   rules={[{ required: false, message: 'Введите часы интервала' }]}
        >
          <InputNumber
            // min={0}
            // max={59}
            style={{
              width: '30%',
            }}
          />
        </Form.Item>
        <span className="ant-form-text" style={{paddingLeft: '5px'}}> {t('shortHours')} </span>
      </Form.Item>

      <Form.Item labelAlign="left" label="Минимальный период" rules={[{ required: false, message: 'Введите интервал' }]} >
        <Form.Item noStyle
                   name={['trendSettingsIndividual', 'analysisPeriodMinDays']}
                   rules={[{ required: false, message: 'Введите сутки интервала' }]}
        >
          <InputNumber
            // min={0}
            // max={999}
            style={{
              width: '30%',
            }}
          />
        </Form.Item>

        <span className="ant-form-text" style={{paddingLeft: '5px'}}> {t('shortDays')} </span>
        <Form.Item noStyle
                   name={['trendSettingsIndividual', 'analysisPeriodMinHours']}
                   rules={[{ required: false, message: 'Введите часы интервала' }]}
        >
          <InputNumber
            // min={0}
            // max={59}
            style={{
              width: '30%',
            }}
          />
        </Form.Item>
        <span className="ant-form-text" style={{paddingLeft: '5px'}}> {t('shortHours')} </span>
      </Form.Item>

      <Form.Item labelAlign="left" label="Максимальный период" rules={[{ required: false, message: 'Введите интервал' }]} >
        <Form.Item noStyle
                   name={['trendSettingsIndividual', 'analysisPeriodMaxDays']}
                   rules={[{ required: false, message: 'Введите сутки интервала' }]}
        >
          <InputNumber
            // min={0}
            // max={999}
            style={{
              width: '30%',
            }}
          />
        </Form.Item>

        <span className="ant-form-text" style={{paddingLeft: '5px'}}> {t('shortDays')} </span>
        <Form.Item noStyle
                   name={['trendSettingsIndividual', 'analysisPeriodMaxHours']}
                   rules={[{ required: false, message: 'Введите часы интервала' }]}
        >
          <InputNumber
            // min={0}
            // max={59}
            style={{
              width: '30%',
            }}
          />
        </Form.Item>
        <span className="ant-form-text" style={{paddingLeft: '5px'}}> {t('shortHours')} </span>
      </Form.Item>

      <Form.Item labelAlign="left" label="Минимальная частота данных" rules={[{ required: false, message: 'Введите интервал' }]} >
        <Form.Item noStyle
                   name={['trendSettingsIndividual', 'minDataFrequencyDays']}
                   rules={[{ required: false, message: 'Введите сутки интервала' }]}
        >
          <InputNumber
            // min={0}
            // max={999}
            style={{
              width: '30%',
            }}
          />
        </Form.Item>
        <span className="ant-form-text" style={{paddingLeft: '5px'}}> {t('shortDays')} </span>
        <Form.Item noStyle
                   name={['trendSettingsIndividual', 'minDataFrequencyHours']}
                   rules={[{ required: false, message: 'Введите часы интервала' }]}
        >
          <InputNumber
            // min={0}
            // max={59}
            style={{
              width: '30%',
            }}
          />
        </Form.Item>
        <span className="ant-form-text" style={{paddingLeft: '5px'}}> {t('shortHours')} </span>
      </Form.Item>

      <Form.Item labelAlign="left" label="Дискретность анализа" rules={[{ required: false, message: 'Введите интервал' }]} >
        <Form.Item noStyle
                   name={['trendSettingsIndividual', 'timeDiscrDays']}
                   rules={[{ required: false, message: 'Введите сутки интервала' }]}
        >
          <InputNumber
            // min={0}
            // max={999}
            style={{
              width: '30%',
            }}
          />
        </Form.Item>

        <span className="ant-form-text" style={{paddingLeft: '5px'}}> {t('shortDays')} </span>
        <Form.Item noStyle
                   name={['trendSettingsIndividual', 'timeDiscrHours']}
                   rules={[{ required: false, message: 'Введите часы интервала' }]}
        >
          <InputNumber
            // min={0}
            // max={59}
            style={{
              width: '30%',
            }}
          />
        </Form.Item>
        <span className="ant-form-text" style={{paddingLeft: '5px'}}> {t('shortHours')} </span>
      </Form.Item>

      <Form.Item labelAlign="left" label="Межотчистной период" rules={[{ required: false, message: 'Введите интервал' }]} >
        <Form.Item noStyle
                   name={['trendSettingsIndividual', 'mopDays']}
                   rules={[{ required: false, message: 'Введите сутки интервала' }]}
        >
          <InputNumber
            // min={0}
            // max={999}
            style={{
              width: '30%',
            }}
          />
        </Form.Item>

        <span className="ant-form-text" style={{paddingLeft: '5px'}}> {t('shortDays')} </span>
        <Form.Item noStyle
                   name={['trendSettingsIndividual', 'mopHours']}
                   rules={[{ required: false, message: 'Введите часы интервала' }]}
        >
          <InputNumber
            // min={0}
            // max={59}
            style={{
              width: '30%',
            }}
          />
        </Form.Item>
        <span className="ant-form-text" style={{paddingLeft: '5px'}}> {t('shortHours')} </span>
      </Form.Item>

      <Form.Item labelAlign="left" label={'Дата последней депарафинизации'} name={['trendSettingsIndividual', 'lastMopDate']}>
        <DatePicker locale={locale} />
      </Form.Item>

      <Form.Item labelAlign="left" label={t('comment')} name={['trendSettingsIndividual', 'unapprovalReason']}>
        <TextArea
          label={t('unapprovalReason')}
          rows={3}
          cols={10}
          maxLength={100}
          rules={[{ required: false, message: t('missingComment') }]}
        />
      </Form.Item>

      <div style={{paddingLeft: '20px'}}>
        <Collapse accordion ghost >
          {makeWarningConstrasintsCollapse()}
        </Collapse>
      </div>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 10 }}>
        <Button type="primary" htmlType="submit">
          {t('apply')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default TrendData
