/* eslint-disable */

import React, { useEffect, useState } from "react";
import "antd/dist/antd.min.css";
import {
  Form,
  Button,
  InputNumber,
  Switch,
  Space,
  Input,
  TimePicker,
  DatePicker, //  Typography,
} from "antd";
import { useTranslation } from "react-i18next";

const TrendConstraintApprox = ({
  handleSetNewData,
  constraintId,
  constraintData,
  settingsType,
}) => {
  return (
    <>
      <Form.Item
        labelAlign="left"
        label={`Коэф. угла наклона апроксимируемой прямой`}
        rules={[
          { required: true, message: "Введите ограничения для апроксимации" },
        ]}
      >
        <span className="ant-form-text" style={{ paddingLeft: "5px" }}>
          {" "}
          1{" "}
        </span>
        <Form.Item
          noStyle
          name={[
            `${settingsType}`,
            `${constraintId}`,
            `approxConstraintsFirst`,
          ]}
          rules={[
            { required: true, message: "Введите ограничения для апроксимации" },
          ]}
        >
          <InputNumber
            // min={0}
            // max={999}
            step="0.00001"
            style={{
              width: "30%",
            }}
          />
        </Form.Item>

        <span className="ant-form-text" style={{ paddingLeft: "5px" }}>
          {" "}
          2{" "}
        </span>
        <Form.Item
          noStyle
          name={[`${settingsType}`, `${constraintId}`, `approxConstraintsLast`]}
          rules={[
            { required: true, message: "Введите ограничения для апрокимации" },
          ]}
        >
          <InputNumber
            // min={0}
            // max={999}
            step="0.00001"
            style={{
              width: "30%",
            }}
          />
        </Form.Item>
      </Form.Item>
    </>
  );
};

export default TrendConstraintApprox;
