/* eslint-disable */
import React, { useState } from "react";
import { Field } from "react-final-form";
import {useTranslation} from "react-i18next";
import SwitchRenderSteps from "formAdapters/Switch/SwitchRenderSteps";
import RangingSettings
  from "../../../WellTree/components/MenuData/components/ControlPanel/components/AnalysisModal/components/RangingSettings";
import VirtualMeasureCollapse from "../../collapses/VirtualMeasureCollapse";
import TrendAnalysisWarningCollapse from "../../collapses/TrendAnalysisWarningCollapse";
import DivergenceAnalysisCollapse from "../../collapses/DivergenceAnalysisCollapse";
import { ReactComponent as NextArrowIcon } from "assets/images/nextArrowIcon.svg";


const RenderSteps = ({
                       form,
                       step,
                       setStep,
                       analysis,
                       setButtonText,
                       formatedReasons,
                       reasonsProps,
                       postSettings,
                       showExtended,
                       ids,
                       wellsData,
                       handleSearchWells,
                       setIds,
                       isSuperAdmin,
                       divergenceData,
                       setDivergenceData,
                       divergenceSettingsData,
                       onVirtualMeasureApply,
                       trendCommonSettingsData,
                       trendIndividualSettingsData,
                       setTrendIndividualSettingsData
                      // rangingSettings,

}) => {
  const {t} = useTranslation();
  const commonIndividual = [t("commonSettings"), t("individualSettings")];
  const [activePanel, setActivePanel] = useState(1);

  const settings = {
    analysisTypes: {
      qj: t("qj"),
      qn: t("qn"),
      obv: t("waterCutShort"),
      instant: t("instantaneous"),
      trending: t("trending"),
      /*potential: t("potential"),
      custom: t("custom"),*/
    },
  };

  switch (step) {
    case "analysis": {
      let analysisData = Object.assign({}, settings.analysisTypes);

      analysisData.qj = analysis?.qj;
      analysisData.qn = analysis?.qn;
      analysisData.obv = analysis?.obv;
      analysisData.instant = analysis?.instant;
       analysisData.trending = analysis?.trending;
      /* analysisData.potential = analysis?.potential;
       analysisData.custom = analysis?.custom;*/

      return (
        <div className="switch-wrapper">
          {Object.keys(analysisData).map((item, index) => (
            <Field
              key={index}
              name={item}
              label={settings.analysisTypes[item]}
              component={SwitchRenderSteps}
              validate={() => setButtonText(t("apply"))}
            />
          ))}
        </div>
      );
    }

    case "reasons": {
      return (
        <div className="switch-wrapper">
          {Object.keys(formatedReasons).map((item, index) => (
            <Field
              key={index}
              //type="checkbox"
              name={item}
              label={reasonsProps[index]?.name}
              component={SwitchRenderSteps}
              validate={() => setButtonText(t("apply"))}
            />
          ))}
        </div>
      );
    }

    /* case "instantCommonSettings": {
       return (
         <div className="switch-wrapper">
           <MakeWarningCollapse
             instantCommonSettings={instantCommonSettings}
             handleSetNewData={postSettings}
             commonIndividual={commonIndividual}
             warningData={warningData}
             wellsData={wellsData}
             handleSearch={handleSearchWells}
           />
         </div>
       );
     }*/

     case "trendAnalysisSettings": {
       return (
         <div className="switch-wrapper">
           <TrendAnalysisWarningCollapse
             trendCommonSettingsData={trendCommonSettingsData}
             trendIndividualSettingsData={trendIndividualSettingsData}
             postSettings={postSettings}
             commonIndividual={commonIndividual}
             // warningData={warningData}
             wellsData={wellsData}
             handleSearch={handleSearchWells}
           />
         </div>
       );
     }

    case "rangingSettings": {
      return (
        <div className="switch-wrapper">
          <RangingSettings handleSetNewData={postSettings} />
        </div>
      );
    }

    case "virtualMeasureSettings": {
      return (
        <div className="switch-wrapper">
          <VirtualMeasureCollapse
            ids={ids}
            wellsData={wellsData}
            handleSearchWells={handleSearchWells}
            activePanel={activePanel}
            setActivePanel={setActivePanel}
            setIds={setIds}
            isSuperAdmin={isSuperAdmin}
            onVirtualMeasureApply={onVirtualMeasureApply}
          />
        </div>
      );
    }

    case "divergenceSettings": {
      return (
        <div className="switch-wrapper">
          <DivergenceAnalysisCollapse
            wellsData={wellsData}
            setDivergenceData={setDivergenceData}
            handleSearch={handleSearchWells}
            divergenceSettingsData={divergenceSettingsData}
            divergenceData={divergenceData}
            commonIndividual={commonIndividual}
            postSettings={postSettings}
          />
        </div>
      );
    }

    /*  case "specialAnalysisSettings": {
        return (
          <div className="switch-wrapper">
            <SpecialAnalysisCollapse
              specialSettingsData={specialSettingsData}
              specialData={specialData}
              postSettings={postSettings}
              commonIndividual={commonIndividual}
              wellsData={wellsData}
              handleSearchWells={handleSearchWells}
            />
          </div>
        );
      }*/

    /*case "setpointsSettings": {
      return (
        <div className="switch-wrapper">
          <SetpointsCollapse
            setpointsData={setpointsData}
            postSettings={postSettings}
            setpointsIndividualData={setpointsIndividualData}
            commonIndividual={commonIndividual}
            handleSearchWells={handleSearchWells}
            wellsData={wellsData}
          />
        </div>
      );
    }*/

    default: {
      return (
        <>
          <div
            className="choose-settings-container"
            onClick={() => {
              setStep("analysis");
              form.initialize(analysis);
            }}
          >
            <span className="choose-title">{t("analysisSettings")}</span>
            <NextArrowIcon />
          </div>
          {showExtended && (
            <div
              className="choose-settings-container"
              onClick={() => {
                setStep("reasons");
                form.initialize(formatedReasons);
              }}
            >
              <span className="choose-title">{t("reasonsSettings")}</span>
              <NextArrowIcon />
            </div>
          )}
          {/* {showExtended && (
              <div
                className="choose-settings-container"
                onClick={() => {
                  setStep("instantCommonSettings");
                  form.initialize(instantCommonSettings);
                }}
              >
                <span className="choose-title">
                  {t("instantCommonSettings")}
                </span>
                <NextArrowIcon />
              </div>
            )}*/}
          <div
              className="choose-settings-container"
              onClick={() => {
                setStep("trendAnalysisSettings");
                form.initialize(wellsData);
              }}
            >
              <span className="choose-title">
                {t("trendAnalysisSettings")}
              </span>
              <NextArrowIcon />
            </div>
          <div
            className="choose-settings-container"
            onClick={() => {
              setStep("rangingSettings");
             // form.initialize(rangingSettings);
            }}
          >
            <span className="choose-title">{t("rangingSettings")}</span>
            <NextArrowIcon />
          </div>
          <div
            className="choose-settings-container"
            onClick={() => {
              setStep("virtualMeasureSettings");
              form.initialize(wellsData);
            }}
          >
              <span className="choose-title">
                {t("Virtual_flow_meter_settings")}
              </span>
            <NextArrowIcon />
          </div>
          <div
              className="choose-settings-container"
              onClick={() => {
                setStep("divergenceSettings");
                form.initialize(wellsData);
              }}
            >
              <span className="choose-title">
                {t("DivergenceSettings")}
              </span>
              <NextArrowIcon />
          </div>
          {/*  <div
              className="choose-settings-container"
              onClick={() => {
                setStep("specialAnalysisSettings");
                form.initialize(specialData);
              }}
            >
              <span className="choose-title">
                {t("specialAnalysisSettings")}
              </span>
              <NextArrowIcon />
            </div>*/}
          {/*  <div
              className="choose-settings-container"
              onClick={() => {
                setStep("setpointsSettings");
                form.initialize(setpointsData);
              }}
            >
              <span className="choose-title">{t("setpointsSettings")}</span>
              <NextArrowIcon />
            </div>*/}
        </>
      );
    }
  }
};

export default React.memo(RenderSteps)